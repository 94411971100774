var MLCV = MLCV || {};

jQuery(document).ready(function () {

    //FOR PRINT
    var header = jQuery('.print-wrapper .collapse-expand-a .logo-wrapper');
    if (!jQuery(header).is('first-child')) {
        jQuery('.print-wrapper div.collapse-box').first().prepend(header);
    }

    var blogIntro = jQuery('.print-wrapper .collapse-expand-a .people-intro');
    if (!jQuery(blogIntro).is('first-child')) {
        jQuery('.print-wrapper div.collapse-box').first().prepend(blogIntro);
    }
    else if (blogIntro.length > 0) {
        jQuery(blogIntro).insertAfter(jQuery('.print-wrapper .collapse-box .logo-wrapper'));
    }

    //console.log(blogIntro);
    if (jQuery(blogIntro).is('last-child')) {
        console.log("lastChild");
    }

    //BlogHomePage KeyContactsPrint Location
    if (jQuery('.print-wrapper .collapse-box .blog_problem').length > 0 && jQuery('.print-wrapper .collapse-box .keycontact-print').length > 0) {
        $blogHomeKeyContacts = jQuery('.print-wrapper .collapse-box').find('.keycontact-print');
        if ($blogHomeKeyContacts.length > 0) {
            $blogHomeKeyContacts.insertAfter('.print-wrapper .collapse-box .people-intro h1');
        }
    }

    //BlogsTopicPagePrint KeyContactsPrint Location
    if (jQuery('.print-wrapper .collapse-box .keycontact-print + .mainContentContainer').length > 0) {
        $topicKeyContacts = jQuery('.print-wrapper .collapse-box').find('.keycontact-print');
        if ($topicKeyContacts.length > 0) {
            $topicKeyContacts.insertAfter('.print-wrapper .collapse-box .mainContentContainer .mainContentArea .blog_description');
        }
    }

    //BlogsArticleDetailPrint KeyContactsPrint Location
    if (jQuery('.print-wrapper .blog-intro').length > 0) {
        $articleKeyContacts = jQuery('.print-wrapper .collapse-box').find('.keycontact-print');
        if ($articleKeyContacts.length > 0) {
            $articleKeyContacts.insertAfter('.print-wrapper .collapse-box .blog-intro .blogpost-heading');
        }
    }

    //Add Related Resources to Blog Home Print
    if (jQuery('.print-wrapper .blog_problem + .side-section-listing').length > 0) {
        var relatedResourceHTML = $("<h2></h2>").text("Related Resources");
        $blogRelatedResources = jQuery('<div>').addClass("related-source").append(relatedResourceHTML);

        $blogRelatedResources.insertBefore(jQuery('.print-wrapper .blog_problem + .side-section-listing'));
    }

    //Add Contributors Listing to Blog Home Print
    if (jQuery('.print-wrapper .collapse-box .contributors-listing').length > 0) {
        $blogContributorsListing = jQuery('.print-wrapper .collapse-box').find('.contributors-listing');
        if ($blogContributorsListing.length > 0) {
            $blogContributorsListing.insertAfter('.print-wrapper .collapse-box .people-intro');
        }
    }

    //FOR MOBILE

    //Add Related Resources to Blog Home Mobile View
    if (jQuery('.layout-mobile .blog_problem').length > 0 && jQuery('.layout-mobile .col-r .side-section-listing').length > 0) {
        var mobileRelatedResourceHTML = $("<h2></h2>").text("Related Resources");
        $blogMobileRelatedResources = jQuery('<div>').addClass("related-source").append(mobileRelatedResourceHTML);

        $blogMobileRelatedResources.insertBefore(jQuery('.layout-mobile .col-r .side-section-listing').first());
    }

    //Add Related Resources to Article Mobile View
    if (jQuery(window).width() <= 640 && jQuery('.collapse-box .blog-intro').length > 0) {
        if (jQuery('.col-r .right-box .side-section-listing.blog-info ~ .side-section-listing').length > 0) {
            var mobileRelatedResourceHTML = $("<h2></h2>").text("Related Resources");
            $blogMobileRelatedResources = jQuery('<div>').addClass("related-source").append(mobileRelatedResourceHTML);

            if (jQuery('.col-r .right-box .side-section-listing.blog-info ~ .side-section-listing h2:contains("SECTORS")').length > 0) {
                $blogMobileRelatedResources.insertBefore(jQuery('.col-r .right-box .side-section-listing.blog-info ~ .side-section-listing').first());
            }
        }

        //Adjust Description when empty
        $articleDesc = jQuery('.blog-intro .page-heading .blog_description');
        if ($articleDesc.length > 0 && $articleDesc.html().trim().length === 0) {
            $articleDesc.css({ 'margin-top': '-5px' });
        }
    }

    //Reposition Topic Title and Topic Description - Topic Page Mobile
    if (jQuery('.layout-mobile .mainContentContainer .mainContentArea .blog_name').length > 0) {
        $topicTitle = jQuery('.layout-mobile .mainContentContainer .mainContentArea .blog_name');
        $topicDescription = jQuery('.layout-mobile .mainContentContainer .mainContentArea .blog_description');
        $topicTitle.insertAfter('.layout-mobile .collapse-box .people-intro .social-media-link-ls:not(.social-media-link-ls--mobile)');
        $topicDescription.insertAfter('.layout-mobile .collapse-box .people-intro .blog_name');


        jQuery('.layout-mobile .col-r.last .side-section-listing').last().css({ 'padding-bottom': '0' });

        //Adjust Topic Description margin when empty
        $description = jQuery('.layout-mobile .people-intro .blog_name + .blog_description');

        if ($description.length > 0 && $description.html().trim().length === 0) {
            $description.css({ 'margin-top': '-7px' });
        }
    }

    //Insert Social Media Share - Topic Page
    if (jQuery('.layout-mobile .mainContentContainer .mainContentArea').length > 0) {
        $socialShareTopicPage = jQuery('.layout-mobile .people-intro .social-media-link-ls');
        if ($socialShareTopicPage.length > 0) {
            jQuery('.layout-mobile .col-l .collapse-box').css({ 'padding-top': '45px', 'padding-left': '9px' });
            $socialShareTopicPage.insertAfter('.layout-mobile .col-l .people-intro');
        }
    }

    //Insert Social Media Share - About Us and Contributors Page
    if (jQuery('.layout-mobile .people-intro .contributors-listing').length > 0) {
        $socialShareTopicPage = jQuery('.layout-mobile .people-intro .social-media-link-ls');

        if ($socialShareTopicPage.length > 0) {
            jQuery('.layout-mobile .col-l .collapse-box').css({ 'padding-top': '45px' });
            $socialShareTopicPage.insertBefore('.layout-mobile .col-l .collapse-expand-a');
        }

        jQuery('.layout-mobile .collapse-box .people-intro div.blog_name').css({ 'margin-bottom': '0' });
        jQuery('.layout-mobile .col-r.last .side-section-listing').last().css({ 'padding-bottom': '0' });
        jQuery('.layout-mobile .people-intro .purple-para p.heading-brief').css({ 'margin-bottom': '5px' });
    }
});

jQuery(window).on("load", function () {

});